//= includes/slick.js
//= includes/mousewheel.js

(function ($, window, document) {
  'use strict';
  var page = {
    init: function () {
      page.achieveSlider();
      page.topSlider();
      page.tabs();
      page.scrollTo();
      page.popups();
      page.googleMap();
    },
    noDev: function () {
      if ((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)) {
        $('.no-dev').removeClass('no-dev');
      }
    },
    isDev: function () {
      return !$('html').hasClass('no-dev') || window.innerWidth < 1200;
    },
    topSlider: function () {
      $('.top-slider').find('.slides').slick({
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1500
      });
    },
    topSliderVideoBg: function () {
      if (!page.isDev()) {
        var video = document.getElementById('slider-video'),
          winHeight = window.innerHeight,
          scrolled = window.scrollY;
        video.classList.add('visible');
        video.play();
        document.addEventListener('scroll', function () {
          scrolled = window.scrollY;
          if (scrolled > winHeight) {
            video.pause();
          } else {
            video.play();
          }
        });
      }
    },
    achieveSlider: function () {
      var achieveSlider = $('.achieve-slider'),
        sliderFor = $('.slider-for'),
        sliderNav = $('.slider-nav');
      sliderFor.slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplaySpeed: 5000,
        asNavFor: '.slider-nav',
        adaptiveHeight: true,
        pauseOnHover: false
      });
      sliderNav.slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplaySpeed: 5000,
        speed: 500,
        asNavFor: '.slider-for',
        focusOnSelect: true
      });

      function autoPlay() {
        if (scrolled < sliderTopPos || sliderBottomPos < scrolled) {
          sliderFor.slick('slickPause');
          sliderNav.slick('slickPause');
        } else {
          sliderFor.slick('slickPlay');
          sliderNav.slick('slickPlay');
        }
      }

      if (!page.isDev()) {
        var sliderTopPos = achieveSlider.offset().top,
          sliderBottomPos = sliderTopPos + achieveSlider.outerHeight(),
          winWidth = window.innerWidth,
          winHeight = window.innerHeight,
          scrolled = (winWidth > 1024) ? window.scrollY + (window.innerHeight / 2) : window.scrollY;
        window.onload = function () {
          autoPlay();
          document.querySelector('.slider-for').style.maxHeight = '100%';
        };
        document.addEventListener('scroll', function () {
          sliderTopPos = achieveSlider.offset().top;
          sliderBottomPos = sliderTopPos + achieveSlider.outerHeight();
          scrolled = (winWidth > 1024) ? window.scrollY + (window.innerHeight / 2) : window.scrollY;
          autoPlay();
        });
      }
    },
    // popups: function () {
    //   $(document).on('click', '.popup-link', function (e) {
    //     e.preventDefault();
    //     var $this = $(this);
    //     var _href = $this.attr('href');
    //     $('html, body').addClass('no-scroll');
    //     $('.popup').addClass('active');
    //     return false;
    //   });
    //   $(document).on('click', '.popup-cancel', function () {
    //     $('.popup').removeClass('active');
    //     $('html, body').removeClass('no-scroll');
    //     $('body, html').animate({
    //       scrollTop: $('.map-wrap').offset().top
    //     }, 5);
    //   });
    // },
    popups: function () {
      $(document).on('click', '.popup-link', function () {
        var $this = $(this);
        var id = $this.data('url');
        $.ajax({
          type: 'post',
          url: ajax_url.ajaxurl,
          data: {
            action: "location",
            id: id,
          },
          success: function (data) {
            $('div.popup-location').html(data.content);
            $('html').addClass('no-scroll');
            $('.popup').addClass('active');
          }
        });
      });
      $(document).on('click', '.popup-cancel', function () {
        $('.popup').removeClass('active');
        $('html').removeClass('no-scroll');
        $('html, body').animate({
          scrollTop: $('.map-wrap').offset().top
        }, 5);
      });
    },
    googleMap: function () {
      var mapWrap = document.querySelector('.map-wrap'),
        lat = 0,
        lng = 0,
        myLatlng = new google.maps.LatLng(lat, lng),
        styles = [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#e4e4e3"
              }
            ]
          },
          {
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "administrative.neighborhood",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "road",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#c9c9c9"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#faf9f9"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          }
        ],
        mapOptions = {
          zoom: 3,
          center: myLatlng,
          scrollwheel: false,
          scaleControl: false,
          draggable: true,
          zoomControl: true,
          disableDoubleClickZoom: true,
          panControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          overviewMapControl: false,
          fullscreenControl: false,
          styles: styles
        },
        map = new google.maps.Map(document.getElementById('map'), mapOptions),
        marker,
        popupLink,
        key;
      for (key in info) {
        marker = new google.maps.InfoWindow({
          position: new google.maps.LatLng(info[key]['lat'], info[key]['lng']),
          map: map,
          url: info[key]['url']
        });
        popupLink = '<span class="popup-link" data-url="' + info[key]['url'] + '"></span>';
        marker.setContent(popupLink);
      }
      google.maps.event.addListenerOnce(map, 'idle', function () {
        var iw = $('.gm-style-iw');
        iw.each(function () {
          $(this).parent().addClass('pin');
        });
        iw.siblings('div, img').remove();
        iw.next().remove();
      });
    },
    tabs: function () {
      var tabNav = $('.tab-nav'),
        tabNavItem = tabNav.find('.tab'),
        tabContent = $('.tab-content'),
        tabContentItem = tabContent.find('.tab'),
        curTab = 0;

      tabNavItem.on('click', function () {
        var $this = $(this);
        curTab = $this.index();
        $this.addClass('active').siblings('.tab').removeClass('active');
        tabContentItem.eq(curTab).addClass('active').siblings('.tab').removeClass('active');
      });
    },
    scrollTo: function () {
      $(document).on('click', '.anchor', function () {
        var $this = $(this),
          anchorURL = $this.attr('data-targetID');
        $('body, html').animate({
          scrollTop: $('#' + anchorURL).offset().top
        }, 500);
        return false;
      });
    },
    servicesVideoBg: function () {
      var videoWrap = document.querySelector('.services'),
        video = document.getElementById('video-bg'),
        coords = videoWrap.getBoundingClientRect();
      document.onscroll = function () {
        coords = videoWrap.getBoundingClientRect();
        if (coords.top < window.innerHeight && coords.bottom > 0) {
          video.play();
        } else {
          video.pause();
        }
      }
    },
    load: function () {
      page.topSliderVideoBg();
      page.servicesVideoBg();
    },
    resize: function () {
    },
    scroll: function () {
    }
  };

  $(document).ready(page.init);
  $(window).on({
    'load': page.load,
    'resize': page.resize,
    'scroll': page.scroll
  });

})(jQuery, window, document);